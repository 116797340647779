import React, { Component } from 'react';

import { connect } from 'react-redux';
import Select from 'react-select';

import './full_search.css';
import TopBar from '../top-bar/top-bar.js';
import { HOST, COOKIES, STRINGLOCAL } from '../../constants.js';
import Store from '../../modules/store.js';
import { Link } from 'react-router-dom';
import Loader from '../loader/loader.js';


class FullSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {loaded: false, ganresList: [], releasesYearList: []}
        this.userLocale = this.props.userLang;
        this.ganres = null
        this.releasesYear = null
        this.isOfflineAct = null
    }

    getSearchResult = () => {
        const params = new URL(window.location.href).searchParams;
        const titleGame = params.get("title");
        const releaseYear = params.get("release_year");
        const ganre = params.get("genre");
        const isOfflineAct = params.get("include_off_act");
        let headersVal = COOKIES.get('jwt_token') ? {
            "Content-Type": 'application/json',
            'Authorization': 'JWT ' + this.jwt,
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,

        } : {
                'Content-Type': 'application/json',
                'Accept-Language': this.userLocale,
                'Content-Language': this.userLocale,
            }
        fetch(HOST + 'gameinfo/game/full_search/', 
            {
                method: 'POST',
                headers: headersVal,
                body: JSON.stringify(
                    {
                        title: titleGame,
                        release_year: releaseYear,
                        genre: ganre,
                        include_off_act: isOfflineAct,
                    }
                )})
                .then(response => response.json())
                .then(json => {
                    Store.dispatch({type: 'FULLSEARCHRESULT', value: json['result']})
                    this.setState({loaded: true})
                    let ganresListVal = []
                    let releaseYearListVal  = []
                    for (var i = 0; i < json['genres'].length; i++) {
                        ganresListVal.push({"value": json['genres'][i], "label": json['genres'][i]})
                    }
                    for (var i = 0; i < json['release_year'].length; i++) {
                        releaseYearListVal.push({"value": json['release_year'][i], "label": json['release_year'][i]})
                    }
                    Store.dispatch({type: 'SEARCHGENRES', value: ganresListVal})
                    Store.dispatch({type: 'SEARCHYEARS', value: releaseYearListVal})
                })        
    }

    componentDidMount() {
        this.getSearchResult()
    }

    buildUrl = () => {
        const params = new URL(window.location.href).searchParams;
        const titleGame = params.get("title");
        var urlParam = []
        if (titleGame) {
            urlParam.push(`title=${titleGame}`)
        }
        if (this.ganres) {
            urlParam.push(this.ganres)
        }
        if (this.releasesYear) {
            urlParam.push(this.releasesYear)
        }
        if (this.isOfflineAct) {
            urlParam.push(this.isOfflineAct)
        }
        let newPath = "/search?" + urlParam.join("&")
        window.history.pushState(null, null, newPath);
        this.getSearchResult()
    }

    selectFilterRelGanres = (event) => {
        let ganres = []
        for (var i = 0; i < event.length; i++) {
            ganres.push(event[i].value)
        }
        this.ganres = `genre=${ganres.join(",")}`
        this.buildUrl()
    }

    selectFilterRelYear = (event) => {
        let years = []
        for (var i = 0; i < event.length; i++) {
            years.push(event[i].value)
        }
        this.releasesYear = `release_year=${years.join(",")}`
        this.buildUrl()
    }

    offlineActCheckBox = (val) => {
        if (val.target.checked) {
            this.isOfflineAct = 'include_off_act=true'
            window.history.pushState(null, null, "/search");
            this.ganres = null
            this.releasesYear = null
            document.getElementById('search-filed').value = ""
            this.buildUrl()
            return
        } else {
            this.isOfflineAct = null
        }
        this.ganres = null
        this.isOfflineAct = null
        this.buildUrl()
    }

    clearSearch = () => {
        this.ganres = null
        this.releasesYear = null
        this.isOfflineAct = null
        document.getElementById('search-filed').value = ""
        window.location.replace(`/search`);
    }

    render() {
        if (!this.state.loaded) {
            return (
                <div className='main-wrapper'>
                    <div className="home-main-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className='main-wrapper main-wrapper-searh'>
                <div className="home-main-container cracked-games main-background">
                    <TopBar hideCalendar={true} />
                    <div className="calendar-wrapper search-results">
                        <div className="calendar-game-wrapper cr-game-wrapper">
                            <div className="game-in-calendar" ref={this.divRef}>
                                {this.props.fullSearchResult.map((obj, i) => {
                                    return (
                                        <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                            <div className="game-cl-us game-cl-aaa">
                                                <img className="calenad-img-game" src={obj.short_image} alt={`Взлом ${obj.title}, технические характеристики`} />
                                                <div className="game-cl-rl-date game-cr-game-date">
                                                    <img src="/calendar-1.svg" />
                                                    <p>{obj.release_date}</p>
                                                </div>
                                                <p className="cr-readable-status">{obj.readable_status}</p>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="filter-block">
                            <div className="filter-block-itm">
                                <span className='filter-title'>{STRINGLOCAL.ganres}</span>
                                <Select
                                    isMulti
                                    className="filter-select-container"
                                    options={this.props.ganresList}
                                    onChange={this.selectFilterRelGanres} />
                                    
                            </div>
                            <div className="filter-block-itm">
                                <span className='filter-title'>{STRINGLOCAL.release_date}</span>
                                <Select
                                    isMulti
                                    className="filter-select-container"
                                    options={this.props.releasesYearList}
                                    onChange={this.selectFilterRelYear} />
                            </div>
                            <div className="filter-block-itm">
                                <span className='filter-title'>{STRINGLOCAL.offline_act}</span>
                                <input type="checkbox" id="scales" name="scales" onChange={this.offlineActCheckBox} />
                            </div>
                            <div className="filter-block-itm">
                                <button onClick={this.clearSearch} className="clear-btn-search" type="button">CLEAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
        fullSearchResult: state.fullSearchResult,
        releasesYearList: state.searchYears,
        ganresList: state.searchGenres,
    }
}

export default connect(mapStateToProps)(FullSearch);