import React, { Component } from 'react';

import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { Adsense } from '@ctrl/react-adsense';

import './cracked-game.css';
import TopBar from '../top-bar/top-bar.js';
import { HOST, STEAMPAYREFERRAL, COOKIES, REFLINK, STRINGLOCAL } from '../../constants.js';
import Loader from '../loader/loader.js';
import Store from '../../modules/store.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import MiddleAdsShort from '../middle-ads/middle-ads-chort.js';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { returnRefGameImg, getAnotherLangPage } from '../utils';


class CrackedGames extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            loadMoreGame: false,
            showPopUp: false
        }
        this.divRef = React.createRef();
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
        this.firstClick = false
        this.userLocale = this.props.userLang;
    }

    showPopUp = () => {
        // if (!this.firstClick) {
        //     let firstShow = parseInt(COOKIES.get('firstShow')) || 0
        //     let countShow = parseInt(COOKIES.get('countShow')) || 0
        //     if (!firstShow && countShow == 3) {
        //         COOKIES.remove('firstShow')
        //         COOKIES.remove('countShow')
        //         COOKIES.set('firstShow', 1)
        //         COOKIES.set('countShow', countShow +=1)
        //         this.setState({showPopUp: true})
        //     } else if (countShow >= 8) {
        //         COOKIES.remove('countShow')
        //         COOKIES.set('countShow', 0)
        //         this.setState({showPopUp: true})
        //     } 
        //     else {
        //         COOKIES.remove('countShow')
        //         COOKIES.set('countShow', countShow +=1)
        //     }
        //     this.firstClick = true
        // }
    }

    clickOnRefGame = () => {
        fetch(HOST + 'gameinfo/game/countrefgame/')
    }

    componentDidMount() {
        this.getCrackedGameList()
        window.addEventListener('scroll', this.scrollHundler);
        this.showPopUp()
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.scrollHundler);
    }

    getCrackedGameList() {
        const params = {headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.userLocale,
            "Content-Language": this.userLocale,
        }}
        fetch(HOST + 'gameinfo/game/lastcrackedgames/', params)
            .then(response => response.json())
            .then(json => {
                Store.dispatch({type: 'LISTCRACKEDGAMES', value: json})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.count_tg_users})
                this.setState({loaded: true, selectOneMonth: true})
            })
    }

    scrollHundler = () => {
        if (this.divRef.current) {
            let scrollY = window.pageYOffset
            let heigghtDiv = this.divRef.current.offsetHeight
            //search for percent of the number
            let calc = scrollY / heigghtDiv * 100
            if (calc >= 12  && !this.state.loadMoreGame) {
                this.setState({loadMoreGame: true})
            }
        }
    }

    render() {
        let countGame = 0
        let showRefGame = true
        if (!this.state.loaded) {
            return (
                <div className='main-wrapper'>
                    <div className="home-main-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className='main-wrapper'>
                {this.state.showPopUp ? <a href={REFLINK} target="_blank" className="ref-absolute-block " onClick={() => this.setState({showPopUp: false})}></a> : ''}
                {/* <LeftSideAds />
                <RightSideAds /> */}
                {/* {this.state.showPopUp ? 
                    <div className="steampay-popup">
                        <div className="steampay-popup-wrapper">
                            <div className="close-popup-top">
                                <p className="close-popup-text special-text" onClick={() => this.setState({showPopUp: false})}>ЗАКРЫТЬ</p>
                            </div>
                            <a href={this.imgRefGame[1]} target="_blank" onClick={() => {this.clickOnRefGame()}}>
                                <img src={this.imgRefGame[0]} />
                            </a>
                        </div>
                    </div> : ''
                } */}
                <Helmet>
                    <title>{STRINGLOCAL.title_cracked_game}</title>
                    <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info/crackedgames/${getAnotherLangPage(this.userLocale)}`} />
                    <link rel='canonical' href={`https://gamestatus.info/crackedgames/${this.userLocale}`} data-react-helmet='true' />
                    <meta property="og:description" content={STRINGLOCAL.description_cracked_game} data-react-helmet='true' />
                    <meta name="description" content={STRINGLOCAL.description_cracked_game} />
                    <meta property="og:image" content="https://gamestat.s3.eu-west-2.amazonaws.com/logo_preview.png" data-react-helmet='true' />
                    <meta property="og:title" content={STRINGLOCAL.og_title_cracked_game} data-react-helmet='true' />
                    <meta property="og:site_name" content={STRINGLOCAL.site_name} data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className="home-main-container cracked-games">
                    <TopBar hideCalendar={true} />
                    <div className="wrapper-logo-cr-game">
                        <img className="calendar-logo pirate-logo" src='/pirate.jpg' alt="Последние взломанные игры на ваш пк" />
                        <div className="wrapper-logo-cr-game-text">
                            <p className="cr-game-year border-cr-logo">{STRINGLOCAL.cracked_game}</p>
                            <div className="flex-cr-game-text">
                                <div>
                                    <p className="cr-game-year prefix-cr-logo">{STRINGLOCAL.per_year}</p>
                                </div>
                                <div>
                                    <p className="cr-game-year cont-game-month">{STRINGLOCAL.month}: {<CountUp end={this.props.listCrackedGames[0]['month_cracked_games']} />}</p>
                                    <p className="cr-game-year cont-game-year">{STRINGLOCAL.year}: {<CountUp end={this.props.listCrackedGames[0]['year_cracked_games']} />}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    {/* <MiddleAds /> */}
                    <h1 className="calendar-title">{STRINGLOCAL.last_cr_game}</h1>
                    <div className="calendar-wrapper">
                        <div className="calendar-game-wrapper cr-game-wrapper">
                            <div className="describe-color">
                                <div className="blue-calendar"></div>
                                <span>{STRINGLOCAL.usual_game}</span>
                                <div className="red-calendar"></div>
                                <span>{STRINGLOCAL.aaa_game}</span>
                            </div>
                            <div className="game-in-calendar" ref={this.divRef}>
                                {this.props.listCrackedGames[0]['list_crack_games'].map((obj, i) => {
                                    countGame += 1
                                    if (!this.state.loadMoreGame && countGame >= 20) {
                                        return
                                    } else {
                                        return (
                                            <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                                <div className="game-cl-us game-cl-aaa">
                                                    <img className="calenad-img-game" src={obj.short_image} alt={`Взлом ${obj.title}, технические характеристики`} />
                                                    <div className="game-cl-rl-date game-cr-game-date">
                                                        <img src="/calendar-1.svg" />
                                                        <p>{obj.crack_date}</p>
                                                    </div>
                                                    <p className="cr-readable-status">{obj.readable_status}</p>
                                                    <p className="num-game">#{countGame}</p>
                                                </div>
                                            </Link>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <BottomInfo />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        listCrackedGames: [state.listCrackedGames],
        userLang: state.userLang,
        countTgUsers: state.tgCountUsers,
    }
}

export default connect(mapStateToProps)(CrackedGames);