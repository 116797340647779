import Cookies from 'universal-cookie';

import { getLangAfterLoad } from './components/utils';

export const MAINHOST = 'https://gamestatus.info';
// export const MAINHOST = 'http://0.0.0.0';
export const DEBUG = false;
// export const DEBUG = true;

export const HOST = `${MAINHOST}/back/api/`;
export const FACEBOOKLOGIN = `${MAINHOST}/back/accounts/facebook/login/`;
export const VKLOGIN = `${MAINHOST}/back/accounts/vk/login/`;
export const COOKIES = new Cookies();
export const STEAMPAYREFERRAL = '6cdbb259-a844-4fb5-bd2e-3ba3e9fe1da3';
export const REFLINK = 'https://ad.admitad.com/g/yog728vijmf406dd6ea7f0d389d68d06b13bd6bf/'
export const ADMINUSERNAME = ['your_admin',];
export const VERSION_SITE = '3.5.11'

const currentDate = new Date()
const currentYear = currentDate.getFullYear()

const ruLocale = {
    'status': 'Cтатус',
    'game_name': 'Название игры',
    'release_date': 'Дата релиза',
    'protect': 'Защита',
    'crack_date': 'Дата взлома',
    'group': 'Взломана',
    'crack': 'Взлом',
    'is_subscribe': 'ОТПИСАТЬСЯ',
    'no_subscribe': 'ПОДПИСАТЬСЯ',
    'login_error': 'Извините, возникли сложно при авторизации',
    'trailer': 'Трейлер',
    'comment': 'Комментарии',
    'sent_comment': 'Отправить',
    'sentCommentTg': 'Отправить в TELEGRAM',
    'abuse': 'Пожаловаться',
    'answer': 'Ответить',
    'miss_comment': 'Комментарии отсутствуют',
    'home_page': 'Главная',
    'offline_act': 'Оффлайн Активация',
    'offlineActPurchase': 'Купить оффлайн активацию',
    'new_cracked_game': 'Новые Взломы Игр',
    'last_craked_game': 'Последние Взломанные Игры',
    'upcoming_releases': 'Ближайшие релизы',
    'home_buttom': 'Статус взлома игр на ПК которые имеют популярны системы защиты: DRM, Denuvo, Steam. Добавь в избранное, что бы не пропустить новые взломы игр',
    'per_year': 'За',
    'month': 'МЕСЯЦ',
    'year': 'ГОД',
    'cracked_game': 'ВЗЛОМАНО ИГР',
    'last_cr_game': 'Последние Взломанные Игры',
    'usual_game': 'Обычная Игра',
    'aaa_game': 'AAA Игра',
    'title_cracked_game': 'Denuvo Crack Status: последние взломанные игры на ПК',
    'description_cracked_game': 'Последние взломанные игры с защитой Denuvo на ваш ПК (PC), которые постоянно обновляються с датами взлома игр.',
    'site_name': 'GameStatus - статус взлома новых игр с защитой Denuvo',
    'og_title_cracked_game': `Список последних взломанных игр ${currentYear} года | Game Status`,
    'home_title': 'Взлом Новых Игр: статус взлома Denuvo игр | Game Status',
    'home_desc': "Не пропусти информацию про новые взломы игр от Skidrow, CPY, Codex. На Game Status вы найдете статус взлома более 15 тысяч игр на ПК разных жанров",
    'minimal_req_crack': '(Минимальные Требования, Таблетка)',
    'min_req_rel_date': '(Минимальные Требования, Дата Выхода)',
    'crack_status_sys_req': 'Статус взлома, минимальные системные требования',
    'req_for': 'Системные требования',
    'system_req_detail': 'Системные требования',
    'system_req_info': 'Ознакомьтесь с минимальными требованиями для комфортной игры на ПК (PC).',
    'cpu': 'Процессор',
    'gpu': 'Видео',
    'your_profile': 'Профиль Пользователя',
    'username': 'Имя пользователя',
    'reg_date': 'Дата Регистрации',
    'subs_list': 'Подписки',
    'comment_count': 'Комментарии',
    'rate_count': 'Рейтинг',
    'your_subs': 'Ваши подписки',
    'no_subs_list': 'Список подписок пуст',
    'your_profile': 'Профиль | Gamestatus',
    'leave_comment': 'Оставьте комментарий',
    'bottom_main_desc': 'Gamestatus предоставляет вводную информацию об играх для ПК. Здесь вы можете увидеть текущий список релизов и узнать, была ли игра взломана. Мы новостной ресурс. Если вам понравилась игра, то мы советуем поддержать разработчиков и купить игру.',
    'bottom_communication': 'Для предложений и сотрудничества:',
    'sign_in_with': 'Войти с помощью:',
    'sign_in': 'ВОЙТИ',
    'change_pass': 'Изменить пароль',
    'fall': 'Осень',
    'spring': 'Весна',
    'summer': 'Лето',
    'winter': 'Зима',
    'title_released_game': 'Новые Вышедшие Игры на ПК | Game Status',
    'released_game_desc': 'Не пропусти новые вышедшие игры на ПК (PC). Игры которые вышли на твой ПК разделены по сезонам для удобного просмотра.',
    'released_game_h1': `Новинки Игр: Вышедшие игры за ${currentYear} Год На ПК`,
    'released_game_h2': 'Выбери для себя игры на ПК (PC) которые уже вышли',
    'detail_game_last_crack_game': 'Последние Взломанные Игры',
    'detail_game_new_game': 'Новые Вышедшие Игры',
    'game_released_on_pc': 'Узнай Какие Игры Вышли На Твой ПК',
    'calendar_home_page': 'Календарь и даты выхода новых ААА игр',
    'games_release_schedule': 'График Выхода Игр: Какие Игры Выйдут',
    'calendar_desc': 'Даты выхода компьютерных игр, постоянно обновляется. В график выхода игр включены самые популярные игры для PC (ПК). GameStatus покажет дату, а еще Вы увидите дату взлома игры.',
    'calendar_pg_title': `График выхода компьютерных игр ${currentYear} года | Game Status`,
    'calendar_bottom_text': 'Не пропусти выход новых игр. Воспользуйся графиком выхода игр с разделением по месяцам, где вы сможете найти даты выхода новых игровых новинок.',
    'game_calendar_top_bar_text': 'Календарь игр',
    'err_mess_post_comment': 'Можно использовать 2 GIF и минимальная длина текста - 10 символов',
    'stop_auto_play': 'не воспроизводить больше',
    'resume_auto_play': 'включить автовоспроизведение',
    'nft_info': 'для поддержки нашего сайта вы можете приобрести NFT токен',
    'gsNewsListTitle': 'GAMESTATUS: Игровой Портал Новых Компьютерных Игр',
    'gsNewsDescription': 'Самые новые игровые новости игр на ПК (PC), PS4, PS5, Xbox. Новинки игры, даты релизов, технические характеристики. Будь всегда самым первым',
    'recentlyCrackedGames': 'Недавно взломанные игры',
    'news': 'НОВОСТИ',
    'ourTelegram': 'ПОДПИШИСЬ НА ОБНОВЛЕНИЯ В TELEGRAM',
    'ourTelegramTwo': 'ПОДПИШИСЬ НА ОБНОВЛЕНИЕ В НАШЕМ TELEGRAM КАНАЛЕ',
    'ourTelegramTwoShort': 'НАШ TELEGRAM КАНАЛ',
    'waitCrack': 'Надоело ждать взлом ?',
    'purchace': 'Купить',
    'offlive_activation_price': 'Оффлайн Активацию за',
    'detail_activation_game': 'ПОДРОБНЕЕ',
    'close_activation_game': 'ЗАКРЫТЬ X',
    'conditionsPurchace': 'Условия Покупки:',
    'conditionsPurchaceAdditionals': 'Дополнительно:',
    'offlineActGameTitle': 'Оффлайн Активация Игр',
    'offlineActGameDesc': 'Надоело ждать взлома игровых новинок? Воспользуйся офлайн доступом, не пропускай новинки игр',
    'buyGame': 'Купить за',
    'otherNews': 'Другие новости',
    'steamListNews': 'Steam Новости',
    'videoListNews': 'Игровые Новости',
    'offlineActivate': 'ОФФЛАЙН АКТИВАЦИЯ',
    'mostViewed': 'Самые Просматриваемые',
    'tgHero': 'поддержать наш сайт',
    'socialLogin': 'Войти с помощью:',
    'oldlLogin': 'или старый способ входа',
    'additional': 'Дополнительно',
    'purchaseOfflineAct': 'Купить оффлайн активацию',
    'usersTG': ' подписчиков',
    'usersTGSubsribe': ' подписаться',
    'descPurchaseGame': ' Описание',
    'userscore': 'Userscore',
    'release_date': 'Дата Релиза',
    'InTelegram': 'В TELEGRAM',
    'InSite': 'НА САЙТЕ',
    "signIn": "Вход",
    "description": "Описание",
    "noComments": "посетите наш TELEGRAM канал",
    "noCommentsOne": "комментарии к этой игре временно отключены",
    "needLoginSponsor": "Чтобы сохранить статус спонсора, пожалуйста, войдите в систему",
    "removeAds": "Remove ADS",
    "downloadTorrent": "СКАЧАТЬ",
    "gameInStore": "STEAM",
    "ganres": "Жанры",
}

const enLocale = {
    'status': 'Status',
    'game_name': 'Game name',
    'release_date': 'Release date',
    'protect': 'Protect',
    'crack_date': 'Crack date',
    'group': 'Group',
    'crack': 'Crack',
    'is_subscribe': 'UNSUBSCRIBE',
    'no_subscribe': 'SUBSCRIBE',
    'login_error': 'Sorry, it is difficult to log in',
    'trailer': 'Trailer',
    'comment': 'Comments',
    'sent_comment': 'Send comment',
    'sentCommentTg': 'Send to TELEGRAM',
    'abuse': 'Complain',
    'answer': 'Reply',
    'miss_comment': 'There are no comments',
    'home_page': 'Home',
    'offlineActPurchase': 'Buy offline activation',
    'offline_act': 'Offline Activation',
    'new_cracked_game': 'New Cracked Games',
    'last_craked_game': 'Latest Cracked Games',
    'upcoming_releases': 'Upcoming releases',
    'home_buttom': 'The status of cracked games on PC with popular protection systems: DRM, Denuvo, Steam. Add to favorites so as not to miss new game hacks',
    'per_year': 'Per',
    'month': 'MONTH',
    'year': 'YEAR',
    'cracked_game': 'CRACKED GAMES',
    'last_cr_game': 'Latest Cracked Games',
    'usual_game': 'Usual Game',
    'aaa_game': 'AAA Game',
    'title_cracked_game': 'Denuvo Crack Status: Latest Hacked PC Games',
    'description_cracked_game': 'Latest cracked games with Denuvo protection on your PC (PC), which are constantly updated with the dates of the hacked games.',
    'site_name': 'GameStatus - new games cracked status with Denuvo protection',
    'og_title_cracked_game': `List of Latest Cracked Games ${currentYear} | Game status`,
    'home_title': 'Crack New Games: Denuvo Games Hacking Status | Game status',
    'home_desc': "Don't miss the information about new crack for games from Skidrow, CPY, Codex. On Game Status, you will find the crack status of more than 15 thousand PC games of different genres.",
    'minimal_req_crack': '(Minimum Requirements, Crack)',
    'min_req_rel_date': '(Minimum Requirements, Release Date)',
    'crack_status_sys_req': 'Crack Status, minimum system requirements',
    'req_for': 'Requirements for',
    'system_req_detail': 'System requirements',
    'system_req_info': 'Check out the minimum requirements to play comfortably on your PC.',
    'cpu': 'CPU',
    'gpu': 'GPU',
    'your_profile': 'Your profile',
    'username': 'Username',
    'reg_date': 'Reg Date',
    'subs_list': 'Subscriptions',
    'comment_count': 'Comments',
    'rate_count': 'Rating',
    'your_subs': 'Your Subscriptions',
    'no_subs_list': 'List of subscriptions is empty',
    'your_profile': 'Profile | GameStatus',
    'leave_comment': 'Leave a comment',
    'bottom_main_desc': 'Gamestatus provides an introduction to PC gaming. Here you can see the current list of releases and see if the game has been cracked. We are a news resource. If you like the game, then we advise you to support the developers and buy the game.',
    'bottom_communication': 'For suggestions and cooperation:',
    'sign_in_with': 'Login With:',
    'sign_in': 'LOGIN',
    'change_pass': 'Change Password',
    'fall': 'Fall',
    'spring': 'Spring',
    'summer': 'Summer',
    'winter': 'Winter',
    'title_released_game': 'New Released PC Games | Game Status',
    'released_game_desc': "Don't miss the new games released on PC. The games that came out on your PC are divided by seasons for easy viewing.",
    'released_game_h1': `New Games: Released Games for ${currentYear} On PC`,
    'released_game_h2': 'Choose for yourself games on PC that are already out',
    'detail_game_last_crack_game': 'Latest Cracked Games',
    'detail_game_new_game': 'New Released Games',
    'game_released_on_pc': 'Find Out What Games Are Out On Your PC',
    'calendar_home_page': 'Calendar and Release Dates For New AAA Games',
    'games_release_schedule': 'Game Release Schedule: Which Games Will Be Out',
    'calendar_desc': 'The release dates of computer games are constantly updated. The most popular PC games are included in the game release schedule. GameStatus will show the date, and you will also see the date the game was hacked.',
    'calendar_pg_title': `${currentYear} PC Games Release Schedule | Game status`,
    'calendar_bottom_text': "Don't miss out on new games coming out. Use the monthly release chart to find the release dates for new games.",
    'game_calendar_top_bar_text': 'Games calendar',
    'err_mess_post_comment': 'You can use 2 GIFs and the minimum text length is 10 characters',
    'stop_auto_play': 'disable autoplay',
    'resume_auto_play': 'enable autoplay',
    'nft_info': 'to support our site, you can purchase NFT token',
    'gsNewsListTitle': 'GAME STATUS: Game Portal of New Computer Games',
    'gsNewsDescription': 'The latest gaming news games on PC (PC), PS4, PS5, Xbox. New games, release dates, specifications. Always be the first',
    'recentlyCrackedGames': 'Recently cracked games',
    'news': 'NEWS',
    'ourTelegram': 'SUBSCRIBE TO UPDATES IN TELEGRAM',
    'ourTelegramTwo': 'SUBSCRIBE TO UPDATE IN OUR TELEGRAM CHANNEL',
    'ourTelegramTwoShort': 'OUR TELEGRAM CHANNEL',
    'waitCrack': 'Tired of waiting to be cracked ?',
    'purchace': 'Buy',
    'offlive_activation_price': 'Offline Activation for',
    'detail_activation_game': 'LEARN MORE',
    'close_activation_game': 'CLOSE',
    'conditionsPurchace': 'Purchase terms:',
    'conditionsPurchaceAdditionals': 'Additionally:',
    'offlineActGameTitle': 'Offline Game Activation',
    'offlineActGameDesc': 'Tired of waiting for new game cracks? Take advantage of offline access, do not miss new games',
    'buyGame': 'Buy',
    'otherNews': 'Other News',
    'steamListNews': 'Steam News',
    'videoListNews': 'Game News',
    'offlineActivate': 'OFFLINE ACTIVATION',
    'mostViewed': 'Most Viewed',
    'tgHero': 'support our site',
    'socialLogin': 'Login with:',
    'oldlLogin': 'or the old login',
    'additional': 'Additionally',
    'purchaseOfflineAct': 'Buy offline activation',
    'usersTG': ' subscribers',
    'usersTGSubsribe': ' subscribe',
    'descPurchaseGame': 'Description',
    'userscore': 'Userscore',
    'release_date': 'Date Release',
    'InTelegram': 'TELEGRAM',
    'InSite': 'SITE',
    "signIn": "Sign In",
    "description": "Description",
    "noComments": "visit our TELEGRAM channel",
    "noCommentsOne": "comments for this game are temporarily disabled",
    "needLoginSponsor": "To maintain your sponsor status, please log in",
    "removeAds": "Remove ADS",
    "downloadTorrent": "DOWNLOAD",
    "gameInStore": "STEAM",
    "ganres": "Ganres",
}

const zhLocale = {
    'socialLogin': '登錄方式:',
    'status': '地位',
    'game_name': '遊戲名稱',
    'release_date': '發布日期',
    'protect': '保護',
    'crack_date': '破解日期',
    'group': '團體',
    'crack': '裂縫',
    'is_subscribe': '退訂',
    'no_subscribe': '訂閱',
    'login_error': '抱歉，登錄有困難',
    'trailer': '預告片',
    'comment': '評論',
    'sent_comment': '發送評論',
    'sentCommentTg': '發送至 TELEGRAM',
    'abuse': '抱怨',
    'answer': '回复',
    'miss_comment': '沒有評論',
    'home_page': '家',
    'offline_act': '離線激活',
    'new_cracked_game': '新破解遊戲',
    'last_craked_game': '最新破解遊戲',
    'upcoming_releases': '即將發布的版本',
    'home_buttom': '具有流行保護系統的 PC 上破解遊戲的狀況：DRM、Denuvo、Steam。 添加到收藏夾，以免錯過新的遊戲技巧',
    'per_year': '每',
    'month': '月',
    'year': '年',
    'offlineActPurchase': '購買離線激活',
    'cracked_game': '破解遊戲',
    'last_cr_game': '最新破解遊戲',
    'usual_game': '普通遊戲',
    'aaa_game': 'AAA級遊戲',
    'title_cracked_game': 'Denuvo 破解狀態：最新被黑客入侵的 PC 遊戲',
    'description_cracked_game': '您的 PC (PC) 上受 Denuvo 保護的最新破解遊戲，會隨著破解遊戲的日期不斷更新。',
    'site_name': 'GameStatus - 新遊戲破解狀態與 Denuvo 保護',
    'og_title_cracked_game': `最新破解遊戲列表 ${currentYear} | 遊戲狀態`,
    'home_title': '破解新遊戲：Denuvo Games 黑客現狀 | 遊戲狀態',
    'home_desc': "不要錯過有關 Skidrow、CPY、Codex 遊戲新破解的信息。 在遊戲狀態中，您可以找到超過15000款不同類型的PC遊戲的破解狀態。",
    'minimal_req_crack': '（最低要求，破解）',
    'min_req_rel_date': '（最低要求、發布日期）',
    'crack_status_sys_req': '破解狀態，最低系統要求',
    'req_for': '要求',
    'system_req_detail': '系統要求',
    'system_req_info': '查看在您的電腦上舒適地玩遊戲的最低要求。',
    'cpu': '中央處理器',
    'gpu': '圖形處理器',
    'your_profile': '你的個人資料',
    'username': '用戶名',
    'reg_date': '註冊日期',
    'subs_list': '訂閱',
    'comment_count': '評論',
    'rate_count': '評分',
    'your_subs': '您的訂閱',
    'no_subs_list': '訂閱列表為空',
    'your_profile': '簡介 | 遊戲狀態',
    'leave_comment': '發表評論',
    'bottom_main_desc': 'Gamestatus 提供了 PC 游戏的介绍。 在这里您可以看到当前的发行列表并查看游戏是否已被破解。 我们是新闻资源。 如果您喜欢该游戏，我们建议您支持开发者并购买该游戏',
    'bottom_communication': '建議及合作：',
    'sign_in_with': '登錄方式：',
    'sign_in': '登錄',
    'change_pass': '更改密碼',
    'fall': '落下',
    'spring': '春天',
    'summer': '夏天',
    'winter': '冬天',
    'title_released_game': '新發布的電腦遊戲 | 遊戲狀態',
    'released_game_desc': "不要錯過 PC 上發布的新遊戲。 PC 上發布的遊戲按季節劃分，以便於觀看。",
    'released_game_h1': `新遊戲：已發布的遊戲 ${currentYear} 在電腦上`,
    'released_game_h2': '為自己選擇已經發布的 PC 遊戲',
    'detail_game_last_crack_game': '最新破解遊戲',
    'detail_game_new_game': '新發布的遊戲',
    'game_released_on_pc': '了解您的電腦上有哪些遊戲',
    'calendar_home_page': '新 AAA 遊戲的日曆和發布日期',
    'games_release_schedule': '遊戲發佈時間表：哪些遊戲將會發布',
    'calendar_desc': '電腦遊戲的發行日期不斷更新。 最流行的電腦遊戲都包含在遊戲發佈時間表中。 GameStatus 將顯示日期，您還將看到遊戲被黑客入侵的日期.',
    'calendar_pg_title': `${currentYear} PC 遊戲發佈時間表 | 遊戲狀態`,
    'calendar_bottom_text': "不要錯過即將推出的新遊戲。 使用每月發布圖表查找新遊戲的發布日期。",
    'game_calendar_top_bar_text': '比賽日曆',
    'err_mess_post_comment': '您可以使用 2 個 GIF，最小文本長度為 10 個字符',
    'stop_auto_play': '禁用自動播放',
    'resume_auto_play': '啟用自動播放',
    'nft_info': '為了支持我們的網站，您可以購買 NFT 代幣',
    'gsNewsListTitle': '遊戲現狀：新電腦遊戲的遊戲門戶',
    'gsNewsDescription': 'PC (PC)、PS4、PS5、Xbox 上的最新遊戲新聞遊戲。 新遊戲、發布日期、規格。 永遠是第一',
    'recentlyCrackedGames': '最近破解的遊戲',
    'news': '消息',
    'ourTelegram': '訂閱 Telegram 中的更新',
    'ourTelegramTwo': '在我們的 Telegram 頻道中訂閱更新',
    'ourTelegramTwoShort': '我們的電報頻道',
    'waitCrack': '厭倦了等待破解？',
    'purchace': '買',
    'offlive_activation_price': '離線激活',
    'detail_activation_game': '了解更多',
    'close_activation_game': '關閉',
    'conditionsPurchace': '購買條款：',
    'conditionsPurchaceAdditionals': '此外：',
    'offlineActGameTitle': '離線遊戲激活',
    'offlineActGameDesc': '厭倦了等待新遊戲破解？ 利用離線訪問，不錯過新遊戲',
    'buyGame': '買',
    'otherNews': '其他新聞',
    'steamListNews': 'Steam 新聞',
    'videoListNews': '遊戲新聞',
    'offlineActivate': '離線激活',
    'mostViewed': '最受關注',
    'tgHero': '支持我們的網站',
    'socialLogin': '登錄方式：',
    'oldlLogin': '或舊的登錄名',
    'additional': '此外',
    'purchaseOfflineAct': '購買離線激活',
    'usersTG': ' 訂戶',
    'usersTGSubsribe': ' 訂閱',
    'descPurchaseGame': '描述',
    'userscore': '用戶評分',
    'release_date': '發布日期',
    'InTelegram': '電報',
    'InSite': '地點',
    "signIn": "登入",
    "description": "描述",
    "noComments": "請造訪我們的 TELEGRAM 頻道",
    "noCommentsOne": "遊戲暫時禁止評論",
    "needLoginSponsor": "要維持您的贊助商身份，請登入",
    "removeAds": "Remove ADS",
    "downloadTorrent": "下载",
    "gameInStore": "STEAM",
    "ganres": "Ganres",
    
}

function handleCurrentLang() {
    const supportLang = {'en': enLocale, 'ru': ruLocale, 'uk': ruLocale, 'ua': ruLocale, 'by': ruLocale, 'kz': ruLocale, 'be': ruLocale, 'zh': zhLocale}
    const lang = getLangAfterLoad()
    if (lang in supportLang) {
        return supportLang[lang]
    } else {
        return enLocale
    }
}
export const STRINGLOCAL = handleCurrentLang()
